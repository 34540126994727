import { Box, Flex, Icon, Text, chakra } from "@chakra-ui/react";
import Link from "next/link";
import { FaDiscord, FaInstagram, FaTiktok } from "react-icons/fa";

import OptimizedImage from "./shared/OptimizedImage";
import GetStartedButtonLoader from "./navbar/GetStartedButtonLoader";
import { useGetEssaysPath } from "@/hooks/useGetEssaysPath";
import { SkewedContainer } from "./shared/generic";

export default function Footer({ posts: serverPosts }) {
  const posts = serverPosts?.length
    ? serverPosts
    : [
        {
          title: "The Right Way to Use AI to Ace Your College Admissions Essay",
          slug: "the-right-way-to-use-ai",
        },
        {
          title: "5 Research Opportunities for High School Students 2023",
          slug: "5_research_opportunities_for_high_school_students_2023",
        },
        {
          title: "Decoding Admissions: Understanding the Rubrics for Evaluating College Essays",
          slug: "decoding_admissions_understanding_the_rubrics",
        },
        {
          title: "5 Essential Books to Bolster Writing Fundamentals for College Essays",
          slug: "5_essential_books_to_bolster_writing_fundamentals",
        },
        {
          title: "Crafting the Perfect 'Why Us' Essay for College Applications",
          slug: "crafting_the_perfect_why_essay",
        },
      ];

  return (
    <Box bg="#2E3746">
      <SkewedContainer
        bg="#2E3746"
        containerProps={{
          as: "footer",
          maxW: "container.xl",
          py: "5rem",
          paddingInlineStart: { base: "2rem", xl: "1rem" },
          paddingInlineEnd: { base: "2rem", xl: "1rem" },
        }}
        top={{ base: "-5em", sm: "-5em", md: "-5em", lg: "-6em", xl: "-8em", "2xl": "-9em" }}
        mt={{ base: 50, md: 150 }}
      >
        <Flex direction="column" color="white">
          <Flex
            justify="space-between"
            flexDir={{ base: "column", md: "row" }}
            gap={{ base: 5, md: 10 }}
            textAlign={{ base: "center", md: "left" }}
          >
            <FooterContents posts={posts} />
          </Flex>

          <Flex
            justify="space-between"
            alignItems="center"
            borderTop="1px solid #414B5A"
            pt={"2rem"}
            mt={"4rem"}
            flexDir={{ base: "column", md: "row" }}
            gap={4}
          >
            <Flex justifyContent="center" gap={2}>
              <SocialIcon icon={FaInstagram} href="https://instagram.com/admityogi" />
              <SocialIcon icon={FaTiktok} href="https://tiktok.com/@admityogi" />
              <SocialIcon icon={FaDiscord} href="https://discord.gg/3ca7ZzwXJR" />
            </Flex>

            <a href="mailto: admityogi@gmail.com" fontSize="xs" mt={1}>
              admityogi@gmail.com
            </a>

            <Text
              maxW="19rem"
              fontSize="xs"
              textAlign={{ base: "center", md: "right" }}
              color="#6B737F"
            >
              Based at Stanford. Copyright © Admit Yogi LLC 2024. Legal Disclaimer: Not endorsed
              by/affiliated with the Common App in any way.
            </Text>
          </Flex>
        </Flex>
      </SkewedContainer>
    </Box>
  );
}

const SocialIcon = ({ icon, href }) => (
  <chakra.a href={href} aria-label={`Social media icon for ${href}`}>
    <Icon as={icon} boxSize={10} border="1px solid #6B737F" borderRadius="6.25rem" p={2} />
  </chakra.a>
);

const FooterItem = ({ children, ...rest }) => (
  <Box fontSize="sm" color="#9AA4B4" lineHeight="150%" mb={1} {...rest}>
    {children}
  </Box>
);

const FooterContents = ({ posts }) => {
  const essaysPath = useGetEssaysPath();

  return (
    <>
      <Box width={{ base: "100%", md: "20%" }} position="relative">
        <Link href="/" legacyBehavior style={{ width: "100%" }}>
          <chakra.a
            href="/"
            mr="auto"
            cursor="pointer"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <OptimizedImage
              src="/images/logo-white.svg"
              alt="AdmitYogi"
              width={176}
              height={36}
              display={{ base: "inline", md: "initial" }}
            />
          </chakra.a>
        </Link>

        <Box
          position="absolute"
          bottom={{ base: "-40rem", md: "2" }}
          left={{ base: "50%", md: "0" }}
        >
          <OptimizedImage
            src="/images/home/vector.svg"
            alt="AdmitYogi"
            width={182}
            height={156}
            display={{ base: "inline", md: "initial" }}
          />
        </Box>
      </Box>

      <Flex
        wrap="wrap"
        gap={{ base: "20px", md: "30px" }}
        justify={{ base: "center", md: "space-between" }}
      >
        <Box minW={{ base: "5rem", md: "6rem" }}>
          <Text fontSize="sm" textTransform="uppercase" fontWeight={700} mb={5} mt={2}>
            About
          </Text>

          <FooterItem>
            <Link href="/about-us/mission">Mission</Link>
          </FooterItem>

          <FooterItem>
            <Link href="/about-us/testimonials">Testimonials</Link>
          </FooterItem>

          <FooterItem>
            <Link href="/faq">FAQ</Link>
          </FooterItem>

          <FooterItem>
            <Link href="/blog">Blog</Link>
          </FooterItem>

          <FooterItem>
            <Link href="/plans">Packages</Link>
          </FooterItem>

          <FooterItem>
            <Link href="/contact-us">Contact Us</Link>
          </FooterItem>
        </Box>

        <Box minW={{ base: "5rem", md: "6rem" }}>
          <Text fontSize="sm" textTransform="uppercase" fontWeight={700} mb={5} mt={2}>
            Product
          </Text>

          <FooterItem>
            <Box position="relative" display="inline-flex">
              <Text
                fontSize="xs"
                display="inline-block"
                mr={2}
                position="absolute"
                left="-4"
                top="0"
              >
                👩‍🎓
              </Text>

              <Link href="/profiles">Profiles</Link>
            </Box>
          </FooterItem>

          <FooterItem>
            <Box position="relative" display="inline-flex">
              <Text
                fontSize="xs"
                display="inline-block"
                mr={2}
                position="absolute"
                left="-4"
                top="0"
              >
                📝
              </Text>

              <Link href={essaysPath}>Essays</Link>
            </Box>
          </FooterItem>

          <FooterItem>
            <Box position="relative" display="inline-flex">
              <Text
                fontSize="xs"
                display="inline-block"
                mr={2}
                position="absolute"
                left="-4"
                top="0"
              >
                📊
              </Text>

              <Link href="/tools/acceptance-rate-calculator">ChanceMe</Link>
            </Box>
          </FooterItem>
        </Box>

        <Box minW={{ base: "5rem", md: "6rem" }}>
          <Text fontSize="sm" textTransform="uppercase" fontWeight={700} mb={5} mt={2}>
            Schools
          </Text>

          <FooterItem>
            <Link href="/example-essays/stanford">Stanford</Link>
          </FooterItem>

          <FooterItem>
            <Link href="/example-essays/harvard">Harvard</Link>
          </FooterItem>

          <FooterItem>
            <Link href="/example-essays/mit">MIT</Link>
          </FooterItem>

          <FooterItem>
            <Link href="/example-essays/columbia">Columbia</Link>
          </FooterItem>

          <FooterItem>
            <Link href="/example-essays/princeton">Princeton</Link>
          </FooterItem>

          <FooterItem>
            <Link href="/example-essays/duke">Duke</Link>
          </FooterItem>

          <FooterItem>
            <Link href="/example-essays/yale">Yale</Link>
          </FooterItem>

          <FooterItem>
            <Link href="/example-essays/dartmouth">Dartmouth</Link>
          </FooterItem>

          <FooterItem>
            <Link href="/example-essays/cornell">Cornell</Link>
          </FooterItem>

          <FooterItem>
            <Link href="/example-essays/uchicago">UChicago</Link>
          </FooterItem>

          <FooterItem>
            <Link href="/example-essays/brown">Brown</Link>
          </FooterItem>
        </Box>

        <Flex
          minW={{ base: "5rem", md: "6rem" }}
          direction="column"
          alignItems={{ base: "center", md: "flex-start" }}
        >
          <Text fontSize="sm" textTransform="uppercase" fontWeight={700} mb={5} mt={2}>
            Recent Posts
          </Text>

          {posts?.map((post, key) => (
            <FooterItem
              key={key}
              sx={{
                maxW: "8rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              <Link href={`/blog/${post.slug}`}>{post.title}</Link>
            </FooterItem>
          ))}
        </Flex>

        <Box>
          <Text
            fontSize="xs"
            textTransform="uppercase"
            fontFamily="heading"
            fontWeight={700}
            mb={5}
            mt={2}
          >
            Website
          </Text>

          <FooterItem>
            <Link href="/privacy-policy">Privacy Policy</Link>
          </FooterItem>

          <FooterItem>
            <Link href="/tos">Terms of Service</Link>
          </FooterItem>

          <FooterItem>
            <Link href="/refund">Refund a Purchase</Link>
          </FooterItem>
        </Box>
      </Flex>

      <GetStartedButtonLoader inverted />
    </>
  );
};
